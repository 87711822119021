#cc--main {
  z-index: 99 !important;
}

#c-bns button:first-child,
#s-bns button:first-child {
  background-color: #904d00;
}

#c-bns button:first-child:hover,
#s-bns button:first-child:hover {
  background-color: #753f02;
}

@media screen and (max-width: 688px) {
  #c-txt {
    font-size: 12px;
  }

  .cc_div #c-bns {
    flex-direction: row;
    justify-content: space-between;
    gap: 6px;
  }

  .cc_div #c-bns .c-bn {
    font-size: 12px;
  }

  #c-bns button:first-child,
  #s-bns button:first-child {
    margin-top: 8px;
  }
}
